export default function () {
  return {
    accounts: [],
    accountsStatus: '',
    ccode: sessionStorage.getItem('bo_account') || window.nxDatalayer.global.ccode,
    chose_one: false,
    current_address: {
      key: '',
      ccode: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      sap_address_id: '',
      addresshash: '',
      acc_desc: '',
      description: '',
      country: '',
    },
    selected_address: {
      key: '',
      ccode: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      sap_address_id: '',
      addresshash: '',
      is_current: 0,
      acc_desc: '',
      description: '',
      country: '',
    },
    one_time_addr: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      sap_address_id: '0',
      acc_desc: ''
    },
    user: {}
  };
}
