<template>
  <div class="drawer__content onetime-address__frame">
    <return-details :changeMode="changeMode" :allowReturn="true" />
    <div class="drawer__header">
      <div class="leftright">
        <h2 class="header2-24">{{ theHeading }}</h2>
        <button
          class="wc-button wc-button--secondary" @click="changeMode('addresses')">
          <span class="label">Use existing address</span>
        </button>
      </div>
    </div>
    <div class="drawer__active account-selector">
      <div class="drawer__active-items">
        <AddressWidget
            :allowManual="true"
            :updateAddressField="updateField"
            :updateFullAddress="updateFullAddress"
            :errors="addressErrors"
            :addressDetails="details"
            :tickValid="false"
            :manualAddressTitle="`Enter address manually`"
            :manualFinderText="`Search for address`"
            :trailingMessages="trailingMessages"
            idRoot="editDetails"
        />
      </div>
      <div class="drawer__active-footer">
        <div class="address-filter__buttons-container">
          <button class="wc wc-button wc-button--secondary" @click="changeMode('details')">
            <span class="label">Cancel</span>
          </button>
          <updated-tip :message="selectErrorMessage" tipType="error hover"
            v-if="!errors.valid" />
          <button class="wc wc-button wc-button--primary"
            ref="oneTimeButtonSelect" @click="chooseAddress">
            <span class="label updating" v-if="showUpdatingSpinner">Updating
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
            <span class="label">Select address</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Stream is auf - Address Up Front
import axios from 'axios';
import { mapState, mapGetters, mapActions } from 'vuex';
import AddressWidget from '@/components/common/AddressWidget/AddressWidget.vue';
import { valid, validate, addressValidationRules } from '@/helpers/validators';
import UpdatedTip from '@/components/common/UpdatedTip/index';

import { doAdobePageView, doAdobeEvents } from '@/helpers/adobe';
import { makeToast } from '@/helpers/helpers';
import ReturnDetails from './ReturnDetails.vue';

export default {
  name: 'OneTimeAddress',
  props: {
    changeMode: {
      type: Function
    },
    closeDrawer: {
      type: Function
    },
    theHeading: {
      type: String
    }
  },
  data: function data() {
    return {
      details: { sap_address_id: '0' },
      trailingMessages: {
        address2: 'We do not deliver to PO boxes or locked bags'
      }
    };
  },
  components: {
    AddressWidget,
    ReturnDetails,
    UpdatedTip,
  },
  computed: {
    ...mapGetters('accountsframe', [
      'user_attrs'
    ]),
    ...mapState('accountsframe', [
      'one_time_addr'
    ]),
    selectErrorMessage() {
      const gotone = [
        'address1',
        'address2',
        'city',
        'state',
        'postcode'
      ].reduce((prev, curr) => prev || (this.details[curr] !== ''), false);

      return gotone ? 'Please correct any errors' : 'Please enter an address';
    },
    errors() {
      const ret = {};
      const rules = {
        ...addressValidationRules,
      };
      const values = {
        address1: this.details.address1,
        address2: this.details.address2,
        city: this.details.city,
        state: this.details.state,
        postcode: this.details.postcode,
      };
      return valid.fields(
        values, rules, {}
      );
    },
    addressErrors() {
      const values = {
        address1: this.details.address1,
        address2: this.details.address2,
        city: this.details.city,
        state: this.details.state,
        postcode: this.details.postcode,
      };
      return valid.fields(
        values, addressValidationRules, {}, {}, {}
      );
    },
  },
  methods: {
    ...mapActions('accountsframe', [
      'updateCurrrentAddress',
      'updateOneTimeAddress',
      'updateSelectedAddress'
    ]),
    chooseAddress() {
      this.saveAddressToSAP(this.details);
    },
    saveAddressToSAP(newAddress, oldAddress) {
      this.showUpdatingSpinner = true;
      doAdobeEvents({ event134: undefined }, {}, 'Address update confirm');

      newAddress.country = 'AU'; // Hardcode to Australia

      const endPoint = `/main-ajax-addressupdate?userid=${window.nxDatalayer.global.userid}`;
      // expects return of either
      // { update: 'ok', err_code: 0 }
      // { update: 'fail', err_code: 999 }
      axios
        .post(endPoint, {
          newAddress,
          oldAddress,
          userid: this.user_attrs.userid,
          post_id: window.nxDatalayer.global.post_id,
          update_order: 1,
        })
        .then((response) => {
          // refresh order and show toast if it worked
          // @ts-ignore
          if (response.data.update === 'ok') {
            // refresh the addresses
            this.updateCurrrentAddress(newAddress);

            doAdobeEvents({ event135: undefined }, {}, 'Address update success');
            this.showUpdatingSpinner = false;

            makeToast({
              text: 'Your address has been changed',
              duration: 9000,
            });

            window.etdp.postcodeChanged(newAddress.postcode);
          } else {
            // prompt if fail
            this.showFailure = true;
            this.showUpdatingSpinner = false;
            doAdobeEvents({ event136: undefined }, {}, 'Address update failure');
          }
        })
        .catch((err) => {
          console.log(err); // eslint-disable-line
          makeToast({
            text: 'An unexpected error occurred, please try again.',
            type: 'error',
            duration: -1
          });
        })
        .finally(() => {
          this.showUpdatingSpinner = false;
          window.location.reload();
        });
    },
    updateField(field) {
      return (ev) => {
        this.details[field] = ev.target.value;
      };
    },
    updateFullAddress(address) {
      this.details.address1 = address.address1;
      this.details.address2 = address.address2;
      this.details.city = address.city;
      this.details.state = address.state;
      this.details.postcode = address.postcode;
    },
  },
  mounted() {
    this.details = { ...this.one_time_addr };
  },
};
</script>
