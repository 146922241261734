<template>
  <nav class="drawer__nav leftright">
    <a href="" class="drawer__link" v-bind:aria-disabled="showDisabled"
      @click.prevent="returnToDetails()">
      <i class="fa-solid fa-arrow-left"></i>
      <span>Return to details</span>
    </a>
  </nav>
</template>

<script>
export default {
  name: 'ReturnDetails',
  props: {
    changeMode: {
      type: Function
    },
    allowReturn: {
      type: Boolean
    }
  },
  computed: {
    showDisabled() {
      return !this.allowReturn;
    }
  },
  methods: {
    returnToDetails() {
      if (this.allowReturn) {
        return this.changeMode('details');
      }

      return false;
    },
  }
};
</script>
