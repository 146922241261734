import Vue from 'vue';

/*
****************************************************************
* Mutation: setAccounts
* Params: data
* Desc: This method is called by getAccounts action to set the invoice state
****************************************************************
*/

export function setAccounts(state, payload) {
  state.ccode = payload.ccode;
  state.user = payload.user;

  Object.keys(payload.rows).forEach((k) => {
    Vue.set(state.accounts, k, payload.rows[k]);
  });
}

export function setCCode(state, ccode) {
  state.ccode = ccode;
}

export function setCurrrentAddress(state, payload) {
  ['addresshash', 'address1', 'address2', 'city', 'state', 'postcode', 'country',
    'acc_desc', 'description', 'ccode', 'sap_address_id'].forEach((field) => {
    Vue.set(state.current_address, field, payload[field]);
  });
}

export function setSelectedAddress(state, payload) {
  ['addresshash', 'address1', 'address2', 'city', 'state', 'postcode', 'country',
    'acc_desc', 'description', 'ccode', 'sap_address_id'].forEach((field) => {
    Vue.set(state.selected_address, field, payload[field]);
  });
}

export function setOneTimeAddress(state, address) {
  Object.keys(address).forEach((k) => {
    Vue.set(state.one_time_addr, k, address[k]);
  });
}

export function setChoseOne(state, did_choose) {
  state.chose_one = did_choose;
}
