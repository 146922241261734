<template>
  <nav class="current-account">
    <h3 class="current-account__title"
      ><i :class="`fa ${display_obj.icon}`"></i>{{ display_obj.heading }}
    </h3>
    <div class="current-account__detail">
      <div v-if="loadingAccounts">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div v-else-if="curr_address.address1">
        <h4 v-if="formattedAccount"
          class="accounts-frame__headinglabel">{{ formattedAccount }}</h4>
        <div>{{ formattedAddress }}</div>
      </div>
      <div style="margin: auto 0" v-else>
        <i class="fa fa-triangle-exclamation"
          style="color: #efa500; margin-right: 8px;"></i>{{ display_obj.unselected }}
      </div>
      <button class="wc wc-button wc-button--secondary" :disabled="isDisabled"
        @click="changeMode(display_obj.mode)">
        <span class="label">{{ display_obj.select_msg }}</span>
      </button>
    </div>
  </nav>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'CurrentAccount',
  props: {
    changeMode: {
      type: Function
    },
    curr_address: {
      type: Object
    },
    display_obj: {
      type: Object
    },
    loadingAccounts: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('accountsframe', [
      'accounts',
    ]),
    isDisabled() {
      if (this.display_obj.mode === 'accounts') {
        if (this.accounts.length === 1) {
          return true;
        }

        // Check for multiple accounts/ccodes
        if (!this.accounts.some((acc, idx, arr) => {
          if (!idx) {
            return false;
          }
          return acc.ccode !== arr[idx - 1].ccode;
        })) {
          return true;
        }
      }

      return false;
    },
    formattedAddress() {
      const address = this.curr_address;
      const {
        description,
        address1,
        address2,
        state,
        postcode,
        city,
        deliverynote,
        selected
      } = address;

      return [address2, address1, city, `${state} ${postcode}`]
        .filter((elem) => typeof elem !== 'undefined' && elem !== '')
        .join(', ');
    },
    formattedAccount() {
      if (this.display_obj.mode === 'accounts') {
        return this.curr_address.acc_desc
          ? `${this.curr_address.ccode} - ${this.curr_address.acc_desc.toUpperCase()}`
          : '';
      }

      return this.curr_address.description
        ? this.curr_address.description.toUpperCase()
        : '';
    }
  }
};
</script>
