/*
use mapState Helper if state is directly used instead of getters
getters is needed only if we need modified/filtered data from state
Eg: invoiceByDocumentNumber
*/

export function all_accounts(state) {
  return state.accounts;
}

export function curr_ccode(state) {
  return state.ccode;
}

export function user_attrs(state) {
  return state.user;
}

export function current_addr(state) {
  return state.current_address;
}

export function selected_addr(state) {
  return state.selected_address;
}
