<template>
  <aside class="drawer__content" aria-labelledby="address-filter__heading">
    <return-details :changeMode="changeMode" :allowReturn="allowReturn" />
    <header class="drawer__header">
      <div class="leftright">
        <h2 class="header2-24" id="address-filter__heading">{{ heading }}</h2>
        <div v-if="showButton">
          <button v-if="user_attrs.allow_changedelivery == 'auspost'"
            class="wc-button wc-button--secondary"
            @click="changeMode('onetime')">
            <span class="label">Home Delivery</span>
          </button>
          <button v-else-if="isRetail"
            class="wc-button wc-button--secondary" @click="changeMode('onetime')">
            <span class="label">New address</span>
          </button>
          <button v-else
            class="wc-button wc-button--secondary" @click="changeMode('onetime')">
            <span class="label">One time address</span>
          </button>
        </div>
      </div>
    </header>
    <div class="drawer__active address-selector">
      <section class="drawer__active-items">
        <nx-filter-list
          :inputId="`account_filter`"
          :inputLabel="inputLabel"
          :items="ccodeAddresses"
          :canEdit="canEdit"
          :filterFunc="filterFunc"
          :isSelected="isSelected"
          :selectItem="selectAddress"
          :stringForFilter="stringForFilter">
          <template v-slot:item="slotProps">
            <one-address-item :item="slotProps.item"
              :getChooseButton="getChooseButton"
              :index="slotProps.index"
              :listMode="listMode"
              :showMapMarker="false"
            />
          </template>
        </nx-filter-list>
        <nx-spinner v-if="showSpinner" />
        <p class="filter-list__message-after" v-else-if="showAfterMessage">
          Can't find your address?
          <a href="/main-support-contactus">Contact us</a>
          to request a new address for this account
        </p>
      </section>
      <footer class="drawer__active-footer">
        <div class="address-filter__message-container" v-if="choseNewOne">
          <message message-type="warn">
            <h4 class="accounts-frame__headinglabel"
              >Selecting this account will update the address for this order.</h4>
            <div>Delivery times and costs may also be updated.</div>
          </message>
        </div>
        <div class="address-filter__buttons-container">
          <button class="wc wc-button wc-button--secondary"
            @click="changeMode('details')">
            <span class="label">Cancel</span>
          </button>
          <button class="wc wc-button wc-button--primary"
            ref="addressSelectorButtonSelect" @click="chooseAddress">
            <span class="label updating" v-if="showUpdatingSpinner">Updating
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
            <span class="label">{{ selectText }}</span>
          </button>
        </div>
      </footer>
    </div>
  </aside>
</template>

<script>
// Stream is auf - Address Up Front
import axios from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';
import NxFilterList from '@/components/common/NxFilterList.vue';
import NxSpinner from '@/components/common/NxSpinner.vue';
import { makeToast } from '@/helpers/helpers';
import { doAdobeEvents } from '@/helpers/adobe';
import OneAddressItem from './OneAddressItem.vue';
import ReturnDetails from './ReturnDetails.vue';
import message from '../common/Message/Message.vue';

export default {
  name: 'AddressSelector',
  components: {
    message,
    NxFilterList,
    NxSpinner,
    OneAddressItem,
    ReturnDetails,
  },
  props: {
    args: {
      type: String
    },
    changeMode: {
      type: Function
    },
    closeDrawer: {
      type: Function
    },
    filterFunc: {
      type: Function
    },
    heading: {
      type: String
    },
    inputLabel: {
      type: String
    },
    listMode: {
      type: String
    },
    mode: {
      type: String
    },
    placeholder: {
      type: String
    },
    selectText: {
      type: String
    },
    showAfterMessage: {
      type: Boolean
    },
    showMapMarker: {
      type: Boolean
    },
    showOneTime: {
      type: Boolean
    },
    stringForFilter: {
      type: Function
    },
  },
  data: function data() {
    return {
      items: [],
      ccode: window.nxDatalayer.global.ccode,
      curr_ccode: window.nxDatalayer.global.ccode,
      fieldId: 'addr',
      showSpinner: false,
      showUpdatingSpinner: false,
      showFailure: false,
      sap_address_id: null,
      tmp_key: null
    };
  },
  computed: {
    ...mapGetters('accountsframe', [
      'user_attrs',
      'current_addr',
      'selected_addr',
    ]),
    ...mapState('accountsframe', [
      'accounts',
      'chose_one',
    ]),
    allowReturn() {
      return !!this.selected_addr.postcode;
    },
    ccodeAddresses() {
      if (this.accounts.length === 0) return [];
      return this.accounts;
    },
    canEdit() {
      return true;
    },
    choseNewOne() {
      return this.chose_one;
    },
    isRetail() {
      return window.nxDatalayer?.global?.channel === 'retail';
    },
    showButton() {
      return (this.showOneTime &&
        ((this?.user_attrs?.allow_changedelivery !== '0') ||
        (window.nxDatalayer?.global?.channel === 'retail')));
    }
  },
  methods: {
    ...mapActions('accountsframe', [
      'updateCurrrentAddress',
      'updateSelectedAddress'
    ]),
    getChooseButton() {
      return this.$refs.addressSelectorButtonSelect;
    },
    selectAddress(address) {
      this.tmp_key = address.key;
    },
    scrollSelectedAddressBySapId() {
      const allAddresses = this.items;
      const addressEdited = allAddresses.find((obj) => obj.sap_address_id === this.sap_address_id);

      // Scroll to Edited Address
      const scrollDiv = document.getElementById('addressListScrollDiv');
      const scrollDivOffset = scrollDiv.offsetTop;
      const targetDiv = document.querySelector(`div[for="${addressEdited.actualIndex}"]`);
      const targetDivOffset = targetDiv.offsetTop;
      // 100 offset to move little down.
      scrollDiv.scrollTop = targetDivOffset - scrollDivOffset - 100;
    },
    isSelected(the_item) {
      return (((the_item.sap_address_id && this.selected_addr.sap_address_id) &&
        (the_item.sap_address_id === this.selected_addr.sap_address_id)) ||
        ((the_item.addresshash && this.selected_addr.addresshash) &&
        (the_item.addresshash === this.selected_addr.addresshash)));
    },
    chooseAddress() {
      this.saveAddressToSAP(this.selected_addr);
    },
    saveAddressToSAP(newAddress, oldAddress) {
      this.showUpdatingSpinner = true;
      doAdobeEvents({ event134: undefined }, {}, 'Address update confirm');

      const endPoint = `/main-ajax-addressupdate?userid=${window.nxDatalayer.global.userid}`;
      // expects return of either
      // { update: 'ok', err_code: 0 }
      // { update: 'fail', err_code: 999 }
      axios
        .post(endPoint, {
          newAddress,
          oldAddress,
          userid: this.user_attrs.userid,
          post_id: window.nxDatalayer.global.post_id,
          update_order: 1,
        })
        .then((response) => {
          // refresh order and show toast if it worked
          // @ts-ignore
          if (response.data.update === 'ok') {
            // refresh the addresses
            // this.sap_address_id = oldAddress.sap_address_id;
            this.updateCurrrentAddress(newAddress);

            doAdobeEvents({ event135: undefined }, {}, 'Address update success');
            this.showUpdatingSpinner = false;

            makeToast({
              text: 'Your address has been changed',
              duration: 9000,
            });

            window.etdp.postcodeChanged(newAddress.postcode);
          } else {
            // prompt if fail
            this.showFailure = true;
            this.showUpdatingSpinner = false;
            doAdobeEvents({ event136: undefined }, {}, 'Address update failure');
          }
        })
        .catch((err) => {
          console.log(err); // eslint-disable-line
          makeToast({
            text: 'An unexpected error occurred, please try again.',
            type: 'error',
            duration: -1
          });
        })
        .finally(() => {
          this.showUpdatingSpinner = false;
          window.location.reload(); // Reload page so postcode takes affect
        });
    },
  }
};
</script>
